import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function createSvgIcon(
  path: React.ReactNode,
  displayName: string,
) {
  const Component = (
    props: React.ComponentProps<typeof SvgIcon>,
    ref: React.Ref<any>,
  ) => (
    <SvgIcon
      data-testid={`${displayName}Icon`}
      ref={ref}
      viewBox="0 0 16 16"
      overflow="visible"
      {...props}
    >
      {path}
    </SvgIcon>
  );

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return React.memo(React.forwardRef(Component));
}
