import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <rect height="4" width="16" />
    <path d="M9,12.6V6H7v6.6l-3-3L2.6,11l4.7,4.7C7.5,15.9,7.7,16,8,16s0.5-0.1,0.7-0.3l4.7-4.7L12,9.6L9,12.6z" />
  </g>,
  'Download',
);
