import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const usePageTitle = (title: string) => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = `${title} • ${t('prism-header:culture-cloud')}`;
  }, [title, t]);
};

export default usePageTitle;
