import { IFeatureFlagClient } from '../../core/IFeatureFlagClient';
import React from 'react';
import getFeatureFlagContext from './FeatureFlagContext';

type FeatureFlagProviderProps = {
  client: IFeatureFlagClient;
  children: React.ReactNode;
};

/**
 * FeatureFlagProvider is a React component that provides a IFeatureFlagClient to all
 * child components. This component should be used at the root of your application.
 **/
export default function FeatureFlagProvider({
  client,
  children,
}: FeatureFlagProviderProps) {
  const FeatureFlagContext = getFeatureFlagContext();
  return (
    <FeatureFlagContext.Consumer>
      {(context) => {
        // this line should be covered in tests
        /* istanbul ignore next */
        if (client && context.client !== client) {
          context = Object.assign({}, context, { client });
        }
        return (
          <FeatureFlagContext.Provider value={context}>
            {children}
          </FeatureFlagContext.Provider>
        );
      }}
    </FeatureFlagContext.Consumer>
  );
}
