import React from 'react';
import createSvgIcon from '../../createSvgIcon';
export default createSvgIcon(
  <g clip-path="url(#clip0_1375_6674)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 0C12.9183 0 16.5 3.58173 16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08173 16 0.5 12.4183 0.5 8C0.5 3.58173 4.08173 0 8.5 0Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.44545 6.00478C5.46581 5.97172 5.59769 5.86802 5.7905 5.86802C5.98332 5.86802 6.11519 5.97172 6.13554 6.00478C6.34229 6.34076 6.78225 6.4455 7.11822 6.23876C7.4542 6.032 7.55895 5.59204 7.3522 5.25607C7.01503 4.70818 6.36038 4.43945 5.7905 4.43945C5.22062 4.43945 4.56597 4.70818 4.2288 5.25607C4.02205 5.59204 4.1268 6.032 4.46278 6.23876C4.79874 6.4455 5.23871 6.34076 5.44545 6.00478ZM11.5572 6.00478C11.5368 5.97172 11.4049 5.86802 11.2121 5.86802C11.0193 5.86802 10.8874 5.97172 10.8671 6.00478C10.6603 6.34076 10.2204 6.4455 9.8844 6.23876C9.54844 6.032 9.44368 5.59204 9.65044 5.25607C9.9876 4.70818 10.6422 4.43945 11.2121 4.43945C11.782 4.43945 12.4367 4.70818 12.7738 5.25607C12.9806 5.59204 12.8758 6.032 12.5399 6.23876C12.2039 6.4455 11.7639 6.34076 11.5572 6.00478ZM12.948 9.1543C12.948 8.83871 12.6921 8.58287 12.3766 8.58287H4.62612C4.31053 8.58287 4.05469 8.83871 4.05469 9.1543C4.05469 9.86357 4.39391 10.8269 5.10098 11.6126C5.8271 12.4193 6.94793 13.0473 8.50133 13.0473C10.0547 13.0473 11.1756 12.4193 11.9017 11.6126C12.6087 10.8269 12.948 9.86357 12.948 9.1543Z"
      fill="#0060E0"
    />
  </g>,
  'AnniversaryParticipants',
);
