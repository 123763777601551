import { useContext } from 'react';
import AuthContext, { AuthContextData } from './AuthContext';

export const useAuthContext = (): AuthContextData => {
  const context = useContext(AuthContext);

  return context;
};

export default {
  useAuthContext,
};
