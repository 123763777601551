import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M0.5 9L3 11.5L5.5 9" fill="black" />
    <path
      d="M0.5 9L3 11.5M3 11.5L5.5 9M3 11.5V0.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'SortDescending',
);
