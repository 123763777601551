import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M2,16c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v14C3,15.6,2.6,16,2,16z" />
    <polygon points="15,10 5,10 5,2 15,2 13,6 " />
  </g>,
  'Flag',
);
