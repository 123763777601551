import {
  CustomerPermission,
  DecodedAccessToken,
  Status,
} from '../models/AuthContext';
import { Observable } from 'rxjs';
import TokenHandler from './TokenHandler';

export default class AuthHandler {
  public readonly accessToken$: Observable<string>;
  public readonly decodedToken$: Observable<DecodedAccessToken>;
  public readonly permissions$: Observable<CustomerPermission[]>;
  public readonly status$: Observable<Status>;

  private readonly _tokenHandler: TokenHandler;

  constructor(tokenHandler: TokenHandler) {
    this._tokenHandler = tokenHandler;
    this.accessToken$ = new Observable((subcriber) => {
      tokenHandler.getAccessToken().then((accessToken) => {
        subcriber.next(accessToken);
      });
    });
    this.decodedToken$ = new Observable((subscriber) => {
      tokenHandler.getDecodedToken().then((decodedToken) => {
        subscriber.next(decodedToken);
      });
    });

    this.permissions$ = new Observable((subscriber) => {
      tokenHandler.getPermissions().then((permissions) => {
        subscriber.next(permissions);
      });
    });

    this.status$ = new Observable((subscriber) => {
      tokenHandler.getStatus().then((status) => {
        subscriber.next(status);
      });
    });
  }

  invalidate = () => {
    this._tokenHandler.invalidate();
  };
}
