import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <path
    d="M16.0761 3H8.92387C8.41363 3 7.92428 3.20353 7.56349 3.56582C7.20269 3.9281 7 4.41947 7 4.93182V18.0682C7 18.5805 7.20269 19.0719 7.56349 19.4342C7.92428 19.7965 8.41363 20 8.92387 20H16.0761C16.5864 20 17.0757 19.7965 17.4365 19.4342C17.7973 19.0719 18 18.5805 18 18.0682V4.93182C18 4.41947 17.7973 3.9281 17.4365 3.56582C17.0757 3.20353 16.5864 3 16.0761 3ZM12.4743 19.2273C11.8356 19.2273 11.32 18.7095 11.32 18.0682C11.32 17.4268 11.8356 16.9091 12.4743 16.9091C13.1131 16.9091 13.6287 17.4268 13.6287 18.0682C13.6287 18.7095 13.1131 19.2273 12.4743 19.2273ZM16.4609 16.1364H8.53909V5.31818H16.4609V16.1364Z"
    transform="translate(-5, -4)"
  />,
  'Mobile',
);
