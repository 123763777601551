import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M15,2h-2V0h-2v2H5V0H3v2H1C0.448,2,0,2.448,0,3v2h16V3C16,2.448,15.552,2,15,2z" />
    <path d="M0,6v9c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V6H0z M11,12H9v2H7v-2H5v-2h2V8h2v2h2V12z" />
  </g>,
  'CalendarAdd',
);
