import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g
    fillRule="evenodd"
    stroke="none"
    strokeWidth="1"
    transform="translate(-1193 -725) translate(1193 725)"
  >
    <path d="M6 0h2v6H6z" />
    <path d="M11.7 3.8c-.4-.4-1-.3-1.4.1-.4.4-.3 1 .1 1.4 1 .9 1.6 2.3 1.6 3.7 0 2.8-2.2 5-5 5s-5-2.2-5-5c0-1.4.6-2.8 1.7-3.7.4-.4.4-1 .1-1.4-.4-.4-1-.4-1.4-.1C.9 5.1 0 7 0 9c0 3.9 3.1 7 7 7s7-3.1 7-7c0-2-.8-3.9-2.3-5.2z" />
  </g>,
  'Power',
);
