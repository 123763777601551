import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AuthContext, { AuthContextData } from './AuthContext';
import AuthUserInfoGetter from '../../core/AuthUserInfoGetter';
import IAuthUserInfoGetter from '../../core/IAuthUserInfoGetter';
import AuthHandler from '../../core/AuthHandler';
import TokenHandler from '../../core/TokenHandler';
import { firstValueFrom } from 'rxjs';

type UserInfoUriProps = {
  userInfoUri: string;
  authUserGetter?: undefined;
  authHandler?: AuthHandler;
};

type CustomGetterProps = {
  userInfoUri?: undefined;
  authUserGetter: IAuthUserInfoGetter;
  authHandler?: AuthHandler;
};

type ProvidedAuthProps = {
  authUserGetter?: undefined;
  userInfoUri?: undefined;
  authHandler: AuthHandler;
};

type CommonProps = {
  children: React.ReactNode;
};

type Props = (UserInfoUriProps | CustomGetterProps | ProvidedAuthProps) &
  CommonProps;
type State = Pick<AuthContextData, 'decodedToken' | 'permissions' | 'status'>;

const AuthContextProvider: React.FC<Props> = ({
  children,
  userInfoUri,
  authHandler: incomingAuthHandler,
  authUserGetter: incomingAuthUserGetter,
}) => {
  const authHandler = useMemo(() => {
    if (userInfoUri) {
      return new AuthHandler(
        new TokenHandler(new AuthUserInfoGetter(userInfoUri)),
      );
    }
    if (incomingAuthHandler) {
      return incomingAuthHandler;
    }
    if (incomingAuthUserGetter) {
      return new AuthHandler(new TokenHandler(incomingAuthUserGetter));
    }

    throw new Error('No auth handler provided');
  }, [incomingAuthUserGetter, userInfoUri, incomingAuthUserGetter]);
  const [state, setState] = useState<State>({ status: 'not_initialized' });
  const getAccessToken = useCallback(
    async () => await firstValueFrom(authHandler.accessToken$),
    [authHandler.accessToken$],
  );
  const getDecodedToken = useCallback(
    async () => await firstValueFrom(authHandler.decodedToken$),
    [authHandler.decodedToken$],
  );
  const getPermissions = useCallback(
    async () => await firstValueFrom(authHandler.permissions$),
    [authHandler.permissions$],
  );
  const aggregatedState = useMemo(
    (): AuthContextData => ({
      getAccessToken,
      getDecodedToken: getDecodedToken,
      getPermissions: getPermissions,
      invalidate: authHandler.invalidate,
      status: state.status,
      decodedToken: state.decodedToken,
      permissions: state.permissions,
    }),
    [state, authHandler, getAccessToken, getDecodedToken, getPermissions],
  );

  useEffect(() => {
    const subscription = authHandler.status$.subscribe((status) => {
      setState((cur) => ({ ...cur, status }));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authHandler.status$]);

  useEffect(() => {
    const subscription = authHandler.decodedToken$.subscribe((decodedToken) => {
      setState((cur) => ({ ...cur, decodedToken }));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authHandler.decodedToken$]);

  useEffect(() => {
    const subscription = authHandler.permissions$.subscribe((permissions) => {
      setState((cur) => ({ ...cur, permissions }));
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authHandler.permissions$]);

  useEffect(() => {
    void aggregatedState.getAccessToken();
  }, [aggregatedState.getAccessToken]);

  return (
    <AuthContext.Provider value={aggregatedState}>
      {children}
    </AuthContext.Provider>
  );
};

export default React.memo(AuthContextProvider);
