interface Person {
  preferredName?: string;
  firstName?: string;
  lastName?: string;
  profile?: {
    pronoun?: string;
  };
}

const getFullName = (person?: Partial<Person>, pronounsEnabled?: boolean) => {
  const firstName = (person?.preferredName || person?.firstName || '').trim();
  const lastName = (person?.lastName || '').trim();
  let fullName = `${firstName} ${lastName}`;
  fullName = fullName.trim();

  return `${fullName}${person?.profile?.pronoun && pronounsEnabled
    ? ` (${person?.profile?.pronoun})`
    : ''}`;
};

export default getFullName;
