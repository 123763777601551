import React from "react";
import createSvgIcon from "../createSvgIcon";

export default createSvgIcon(
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 2C4.5 3.10455 3.60455 4 2.5 4C1.39545 4 0.5 3.10455 0.5 2C0.5 0.89543 1.39545 0 2.5 0C3.60455 0 4.5 0.89543 4.5 2ZM2.5 11C3.60455 11 4.5 10.1046 4.5 9C4.5 7.89545 3.60455 7 2.5 7C1.39545 7 0.5 7.89545 0.5 9C0.5 10.1046 1.39545 11 2.5 11ZM2.5 18C3.60455 18 4.5 17.1046 4.5 16C4.5 14.8954 3.60455 14 2.5 14C1.39545 14 0.5 14.8954 0.5 16C0.5 17.1046 1.39545 18 2.5 18Z" fill="#212121"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 2C13.5 3.10455 12.6045 4 11.5 4C10.3955 4 9.5 3.10455 9.5 2C9.5 0.89543 10.3955 0 11.5 0C12.6045 0 13.5 0.89543 13.5 2ZM11.5 11C12.6045 11 13.5 10.1046 13.5 9C13.5 7.89545 12.6045 7 11.5 7C10.3955 7 9.5 7.89545 9.5 9C9.5 10.1046 10.3955 11 11.5 11ZM11.5 18C12.6045 18 13.5 17.1046 13.5 16C13.5 14.8954 12.6045 14 11.5 14C10.3955 14 9.5 14.8954 9.5 16C9.5 17.1046 10.3955 18 11.5 18Z" fill="#212121"/>
    </g>,
    'Draggable',
);
