import React from 'react';
import createSvgIcon from '../../createSvgIcon';
export default createSvgIcon(
  <g clip-path="url(#clip0_1375_6747)">
    <path
      d="M5.8214 2.90771C4.82316 2.90771 3.91162 3.6632 3.91162 4.71634V11.6909C3.91162 12.7441 4.82316 13.4995 5.8214 13.4995H16.5903C17.5885 13.4995 18.5001 12.7441 18.5001 11.6909V4.71634C18.5001 3.6632 17.5885 2.90771 16.5903 2.90771H5.8214Z"
      fill="#0060E0"
    />
    <path
      d="M2.40977 0C1.41154 0 0.5 0.755485 0.5 1.80863V8.78316C0.5 9.83632 1.41154 10.5918 2.40977 10.5918H13.1787C14.1769 10.5918 15.0884 9.83632 15.0884 8.78316V1.80863C15.0884 0.755485 14.1769 0 13.1787 0H2.40977Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.79407 2.97607C6.51955 2.97607 5.48633 4.0147 5.48633 5.29592C5.48633 6.57713 6.51955 7.61575 7.79407 7.61575C9.06862 7.61575 10.1018 6.57713 10.1018 5.29592C10.1018 4.0147 9.06862 2.97607 7.79407 2.97607Z"
      fill="#0060E0"
    />
  </g>,
  'PayrollRedeemed',
);
