import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getFeatureFlagContext } from '../contexts/FeatureFlagContext';
import { FlagType } from '../core/IFeatureFlagClient';

type Result<T extends FlagType> = [boolean, T | undefined];

/**
 * @param {string} flagName - feature flag name
 *
 * must be called within the {@link FeatureFlagProvider}
 */
export default function useFeatureFlag<T extends FlagType>(
  flagName: string,
): Result<T> {
  const context = useContext(getFeatureFlagContext());
  const [isInit, setIsInit] = useState(false);
  const [flag, setFlag] = useState<T | undefined>();
  const result: Result<T> = useMemo(() => [isInit, flag], [isInit, flag]);
  const changeCallback = useCallback((value?: T) => {
    setFlag(value);
  }, []);

  useEffect(() => {
    if (!context.client) {
      console.warn("FeatureFlagContext's client is not initialized.");
      return;
    }
    const [unsubscribe, initPromise] = context.client.subscribeToFlag(
      flagName,
      changeCallback,
    );
    initPromise.then(() => {
      setIsInit(true);
    });
    return unsubscribe;
  }, [changeCallback, flagName, context.client]);

  return result;
}
