import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M15.7.3L4 12v4h4L19.7 4.3c.4-.4.4-1 0-1.4L17.1.3c-.4-.4-1-.4-1.4 0z" />
    <path d="M0 0h11v2H0z" />
    <path d="M0 4h6v2H0z" />
    <path d="M0 8h3v2H0z" />
  </g>,
  'EditNote',
);
