import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <polygon data-color="color-2" points="13,3 13,11 16,11 16,0 5,0 5,3 " />
    <rect height="11" width="11" x="0" y="5" />
  </g>,
  'Agenda',
);
