import {
  CustomerPermission,
  DecodedAccessToken,
  Status,
} from '../models/AuthContext';
import AuthUserInfo from './AuthUserInfo';
import IAuthUserInfoGetter from './IAuthUserInfoGetter';

export const userInfoToStatus = (userInfo?: AuthUserInfo): Status => {
  return userInfo?.isExpired() ? 'unauthenticated' : 'authenticated';
};

export default class TokenHandler {
  private readonly getter: IAuthUserInfoGetter;
  private userInfo: AuthUserInfo | undefined;
  private userInfoPromise?: Promise<AuthUserInfo>;

  constructor(getter: IAuthUserInfoGetter) {
    this.getter = getter;
  }

  private getUserInfo = async (): Promise<AuthUserInfo> => {
    if (!this.userInfo || this.userInfo.isExpired()) {
      if (!this.userInfoPromise) {
        this.userInfoPromise = this.getter.getUserInfo();
      }
      try {
        this.userInfo = await this.userInfoPromise;
        this.userInfoPromise = undefined;
      } catch {
        this.userInfoPromise = undefined;
        throw new Error('Failed to get user info');
      }
    }

    return this.userInfo;
  };

  getAccessToken = async (): Promise<string> => {
    const userInfo = await this.getUserInfo();

    return userInfo.accessToken;
  };

  getDecodedToken = async (): Promise<DecodedAccessToken> => {
    const userInfo = await this.getUserInfo();

    return userInfo.decodedAccessToken;
  };

  getPermissions = async (): Promise<CustomerPermission[]> => {
    const userInfo = await this.getUserInfo();

    return userInfo.permissions;
  };

  getStatus = async (): Promise<Status> => {
    try {
      const userInfo = await this.getUserInfo();
      return userInfoToStatus(userInfo);
    } catch {
      return 'unauthenticated';
    }
  };

  invalidate = () => {
    this.userInfo = undefined;
  };
}
