import * as React from 'react';
import createSvgIcon from '../createSvgIcon';

const Video = createSvgIcon(
  <>
    <path d="M1.46387 14.5H15.4639C16.0639 14.5 16.4639 14.1 16.4639 13.5V1.5C16.4639 0.9 16.0639 0.5 15.4639 0.5H1.46387C0.863867 0.5 0.463867 0.9 0.463867 1.5V13.5C0.463867 14.1 0.863867 14.5 1.46387 14.5ZM14.4639 12.5H2.46387V2.5H14.4639V12.5Z" />
    <path d="M11.2808 7.16565L7.02116 4.55373C6.77438 4.40241 6.46387 4.5887 6.46387 4.88808V10.1119C6.46387 10.4113 6.77438 10.5976 7.02116 10.4463L11.2808 7.83435C11.5249 7.68467 11.5249 7.31533 11.2808 7.16565Z" />
  </>,
  'Video',
);

export default Video;
