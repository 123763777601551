import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <polygon points="6,0 0,0 0,6 2,6 2,2 6,2 " />
    <polygon points="10,0 10,2 14,2 14,6 16,6 16,0 " />
    <polygon points="0,10 0,16 6,16 6,14 2,14 2,10 " />
    <polygon points="16,10 16,16 10,16 10,14 14,14 14,10 " />
  </g>,

  'FullScreen',
);
