import React from 'react';
import createSvgIcon from '../../createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d="M0.5 4.93506V13.125C0.5 14.1605 1.33947 15 2.375 15H13.625C14.6605 15 15.5 14.1605 15.5 13.125V4.93506H0.5Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.25 0C4.84173 0 5.32143 0.479695 5.32143 1.07143V1.60714H10.6787V1.07143C10.6787 0.479695 11.1584 0 11.7501 0C12.3419 0 12.8215 0.479695 12.8215 1.07143V1.60714H13.625C14.5756 1.60714 15.5 2.31641 15.5 3.37106V4.13699V4.94056H14.6964H1.30357H0.5V4.13699V3.37106C0.5 2.31641 1.42445 1.60714 2.375 1.60714H3.17857V1.07143C3.17857 0.479695 3.65827 0 4.25 0Z"
      fill="#0060E0"
    />
    <path
      d="M7.41063 6.53763C7.45675 6.41884 7.5377 6.31675 7.64286 6.24477C7.74802 6.1728 7.87248 6.13428 7.99991 6.13428C8.12735 6.13428 8.2518 6.1728 8.35697 6.24477C8.46213 6.31675 8.54307 6.41884 8.5892 6.53763L9.27491 8.28406H11.1071C11.2268 8.29321 11.3417 8.33573 11.4386 8.40683C11.5354 8.47793 11.6104 8.57475 11.655 8.6863C11.6997 8.79786 11.7121 8.91968 11.691 9.03797C11.6699 9.15624 11.6161 9.26625 11.5356 9.35548L9.91777 10.8126L10.6035 12.1841C10.6656 12.3045 10.6866 12.4419 10.6634 12.5753C10.6402 12.7088 10.574 12.831 10.4749 12.9234C10.3765 13.0169 10.2514 13.0774 10.117 13.0967C9.98262 13.1159 9.84556 13.0928 9.72491 13.0305L7.99991 12.0876L6.27491 13.0305C6.15426 13.0928 6.01721 13.1159 5.8828 13.0967C5.7484 13.0774 5.62331 13.0169 5.52491 12.9234C5.42584 12.831 5.35965 12.7088 5.33645 12.5753C5.31324 12.4419 5.33427 12.3045 5.39634 12.1841L6.08205 10.8126L4.4642 9.35548C4.38375 9.26625 4.32993 9.15624 4.30882 9.03797C4.28772 8.91968 4.30018 8.79786 4.34481 8.6863C4.38943 8.57475 4.46442 8.47793 4.56128 8.40683C4.65813 8.33573 4.77297 8.29321 4.89277 8.28406H6.72491L7.41063 6.53763Z"
      fill="#0060E0"
    />
  </g>,
  'AnniversaryEvents',
);
