import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M9,0H7C6.4,0,6,0.4,6,1v14c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V1C10,0.4,9.6,0,9,0z" />
    <path d="M3,10H1c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-4C4,10.4,3.6,10,3,10z" />
    <path d="M15,5h-2c-0.6,0-1,0.4-1,1v9c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z" />
  </g>,
  'Chart',
);
