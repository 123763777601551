import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g fillRule="evenodd" stroke="none" strokeWidth="1">
    <path d="M15 0H1C.4 0 0 .4 0 1v11c0 .6.4 1 1 1h5v1H3v2h10v-2h-3v-1h5c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 2v7H2V2h12z" />
    <path d="M6 3v5l5-2.5z" />
  </g>,
  'PlayScreen',
);
