import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import { FlagType, IFeatureFlagClient } from './IFeatureFlagClient';

export class LaunchDarklyClient implements IFeatureFlagClient {
  private readonly client: LDClient;
  private readonly initializedPromise: Promise<void>;

  constructor(launchDarklyClientId: string) {
    this.client = initialize(
      launchDarklyClientId,
      { kind: 'user', anonymous: true },
      { streaming: true },
    );
    this.initializedPromise = this.client.waitUntilReady();
    this.subscribeToFlag = this.subscribeToFlag.bind(this);
  }

  /**
   * @param {string} flagName - feature flag name
   * @param {function} callback - function to be called with the flag's value
   *
   * @returns {Array} - first value being the unsubscribe function,
   * second a promise to be resolved when the flag is ready
   */
  subscribeToFlag<T extends FlagType>(
    flagName: string,
    callback: (value: T | undefined) => void,
  ): [() => void, Promise<void>] {
    const promise = this.initializedPromise.then(() => {
      const value = this.client.variation(flagName);
      callback(value);
      this.client.on(`change:${flagName}`, callback);
    });

    const unsubscribe = () => {
      this.client.off(`change:${flagName}`, callback);
    };
    return [unsubscribe, promise];
  }
}
