import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M0.5 3L3 0.5L5.5 3" fill="black" />
    <path
      d="M0.5 3L3 0.5M3 0.5L5.5 3M3 0.5V11.5"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  'SortAscending',
);
