import React, { useState } from 'react';
import {
  Badge,
  Box,
  Container,
  Divider,
  Flex,
  IconButton,
  Link,
  List,
  Subheading,
  Text,
} from '@modulz/radix';
import { MenuHamburger } from '@octanner/prism-icons';
import { graphql, StaticQuery } from 'gatsby';
import NavItem from './components/NavItem';
import psmPkg from '../../prism-core/package.json';

function App({ element, props }) {
  const [navOpen, setNavOpen] = useState(false);

  const query = graphql`
    fragment mdxContent on MdxConnection {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
    query {
      pinned: allMdx(
        sort: { order: ASC, fields: [frontmatter___order] }
        filter: { frontmatter: { pinned: { ne: null } } }
      ) {
        ...mdxContent
      }
      components: allMdx(
        sort: { order: ASC, fields: [frontmatter___component] }
        filter: { frontmatter: { component: { ne: null } } }
      ) {
        ...mdxContent
      }
      hooks: allMdx(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { menu: { eq: "hooks" } } }
      ) {
        ...mdxContent
      }
      types: allMdx(
        sort: { order: ASC, fields: [frontmatter___title] }
        filter: { frontmatter: { menu: { eq: "types" } } }
      ) {
        ...mdxContent
      }
      adminComponent: allMdx(
        sort: { order: ASC, fields: [frontmatter___component] }
        filter: { frontmatter: { adminComponent: { ne: null } } }
      ) {
        ...mdxContent
      }
    }
  `;

  const pathname = props.location.pathname;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <>
            <Box
              pb={[0, 8]}
              sx={{
                position: ['static', 'fixed'],
                width: ['100%', 200, 250],
                height: ['auto', '100vh'],
                overflow: ['auto', 'scroll'],
                borderRight: [0, '1px solid'],
                borderBottom: ['1px solid', 0],
                borderColor: ['gray300', 'gray300'],
                WebkitOverflowScrolling: 'touch',
                overflowX: 'hidden',
              }}
            >
              <Box py={2} px={4}>
                <Flex sx={{ alignItems: 'center' }}>
                  <Box>
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 103 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="2"
                        y="3"
                        width="34"
                        height="78"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.77168 80.4124L35.8028 74.7949V3.67178L2.77168 80.4124Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask0)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.77168 80.4124L35.8028 74.7949V3.67178L2.77168 80.4124Z"
                          fill="url(#paint0_linear)"
                        />
                      </g>
                      <mask
                        id="mask1"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="35"
                        y="3"
                        width="64"
                        height="72"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M35.8028 74.7949L98.8999 64.0636L35.8028 3.67178V74.7949Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask1)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M35.8028 74.7949L98.8999 64.0636L35.8028 3.67178V74.7949Z"
                          fill="url(#paint1_linear)"
                        />
                      </g>
                      <mask
                        id="mask2"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="2"
                        y="74"
                        width="34"
                        height="27"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.77168 80.4124L35.8028 100.926V74.7949L2.77168 80.4124Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask2)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.77168 80.4124L35.8028 100.926V74.7949L2.77168 80.4124Z"
                          fill="url(#paint2_linear)"
                        />
                      </g>
                      <mask
                        id="mask3"
                        mask-type="alpha"
                        maskUnits="userSpaceOnUse"
                        x="35"
                        y="64"
                        width="64"
                        height="37"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M35.8028 74.7949V100.926L98.8999 64.0636L35.8028 74.7949Z"
                          fill="white"
                        />
                      </mask>
                      <g mask="url(#mask3)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M35.8028 74.7949V100.926L98.8999 64.0636L35.8028 74.7949Z"
                          fill="url(#paint3_linear)"
                        />
                      </g>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M102.317 64.4674L35.081 0.113739L0.165146 81.2331L35.7704 103.344L102.317 64.4674ZM35.8355 98.5059L5.37852 79.5922L36.5249 7.23033L95.4819 63.6603L35.8355 98.5059Z"
                        fill="#163275"
                      />
                      <path
                        d="M33.7336 3.67197H37.8722V100.925H33.7336V3.67197Z"
                        fill="#163275"
                      />
                      <path
                        d="M3.11813 82.4567L2.42551 78.3686L98.5529 62.0198L99.2455 66.1079L3.11813 82.4567Z"
                        fill="#163275"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="43.9591"
                          y1="-36.9622"
                          x2="-16.8737"
                          y2="-30.4429"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset="1" stopColor="#00FBB5" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear"
                          x1="64.6296"
                          y1="-1.55496"
                          x2="10.2555"
                          y2="23.1998"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#D6C6FF" />
                          <stop offset="1" stopColor="#0080FF" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear"
                          x1="28.5895"
                          y1="68.7761"
                          x2="6.07759"
                          y2="89.5841"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FF7CE4" />
                          <stop offset="1" stopColor="#DD9700" />
                        </linearGradient>
                        <linearGradient
                          id="paint3_linear"
                          x1="62.0945"
                          y1="36.0606"
                          x2="29.7469"
                          y2="90.885"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#B8EBFF" />
                          <stop offset="1" stopColor="#6B00BF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Box>
                  <Badge ml={3} variant="gray">
                    {psmPkg.version}
                  </Badge>
                  <Box ml="auto" sx={{ display: ['block', 'none'] }}>
                    <IconButton
                      size={1}
                      variant={navOpen ? 'active' : undefined}
                      onClick={() => setNavOpen(!navOpen)}
                    >
                      {!navOpen && <MenuHamburger />}
                    </IconButton>
                  </Box>
                </Flex>
              </Box>

              <Box sx={{ display: [navOpen ? 'block' : 'none', 'block'] }}>
                <Divider mb={1} />
                <List>
                  <Subheading mx={5} my={2}>
                    Overview
                  </Subheading>
                  {data.pinned.edges.map(({ node }) => (
                    <NavItem
                      key={node.frontmatter.title}
                      to={node.fields.slug}
                      active={pathname === node.fields.slug}
                      onClick={() => setNavOpen(false)}
                    >
                      {node.frontmatter.title}
                    </NavItem>
                  ))}
                </List>

                <Divider mb={1} />

                <List>
                  <Subheading mx={5} my={2}>
                    Components
                  </Subheading>
                  {data.components.edges.map(({ node }) => (
                    <NavItem
                      key={node.frontmatter.title}
                      to={node.fields.slug}
                      active={pathname === node.fields.slug}
                      onClick={() => setNavOpen(false)}
                    >
                      {node.frontmatter.title}
                    </NavItem>
                  ))}
                </List>

                <Divider mb={1} />

                <List>
                  <Subheading mx={5} my={2}>
                    Admin Components
                  </Subheading>
                  {data.adminComponent.edges.map(({ node }) => (
                    <NavItem
                      key={node.frontmatter.title}
                      to={node.fields.slug}
                      active={pathname === node.fields.slug}
                      onClick={() => setNavOpen(false)}
                    >
                      {node.frontmatter.title}
                    </NavItem>
                  ))}
                </List>

                <Divider mb={1} />

                <List>
                  <Subheading mx={5} my={2}>
                    Hooks
                  </Subheading>
                  {data.hooks.edges.map(({ node }) => (
                    <NavItem
                      key={node.frontmatter.title}
                      to={node.fields.slug}
                      active={pathname === node.fields.slug}
                      onClick={() => setNavOpen(false)}
                    >
                      {node.frontmatter.title}
                    </NavItem>
                  ))}
                </List>

                <Divider mb={1} />

                <List>
                  <Subheading mx={5} my={2}>
                    Types
                  </Subheading>
                  {data.types.edges.map(({ node }) => (
                    <NavItem
                      key={node.frontmatter.title}
                      to={node.fields.slug}
                      active={pathname === node.fields.slug}
                      onClick={() => setNavOpen(false)}
                    >
                      {node.frontmatter.title}
                    </NavItem>
                  ))}
                </List>

                <Divider mb={1} />

                <List>
                  <Subheading mx={5} my={2}>
                    GitHub links
                  </Subheading>

                  <NavItem
                    as="a"
                    href="https://github.com/octanner/prism-ui"
                    isExternal
                  >
                    Prism
                  </NavItem>
                  <NavItem
                    as="a"
                    href="https://github.com/octanner/prism-ui/tree/master/packages/website"
                    isExternal
                  >
                    Prism Docs
                  </NavItem>
                </List>

                <Divider mb={1} />

                <Box px={5} mb={2} mt={3} sx={{ minHeight: 6 }}>
                  <Text size={2}>
                    Powered by{' '}
                    <Link href="https://modulz.app" title="Modulz">
                      Modulz
                    </Link>
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box
              pt={8}
              pb={9}
              marginLeft={[0, 200, 250]}
              sx={{
                maxWidth: ['100%'],
                display: [navOpen ? 'none' : 'block', 'block'],
              }}
            >
              <Container size={1}>{element}</Container>
            </Box>
          </>
        );
      }}
    />
  );
}

export default App;
