import * as React from 'react';
import createSvgIcon from '../createSvgIcon';

const Crop = createSvgIcon(
  <>
    <path d="M11.5262 3.32094C12.4393 3.32094 13.1791 4.06066 13.1791 4.97383V10.5H11.2837V5.21625H6V3.32094H11.5262Z" />
    <path d="M2.82094 12.0262V5.21625H0V3.32094H2.82094V0.5H4.71625V11.7837H16V13.6791H13.1791V16.5H11.2837V13.6791H4.47383C3.56066 13.6791 2.82094 12.9393 2.82094 12.0262Z" />
  </>,
  'Crop',
);

export default Crop;
