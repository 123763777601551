import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <circle cx="8" cy="8" r="7" stroke="black" strokeWidth="2" fill="white" />
    <polygon points="7,7 5,7 4,7 4, 9 5,9 7,9 9,9 11,9 12,9 12,7 11,7 9,7 " />
  </g>,
  'MinusInCircle',
);
