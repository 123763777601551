import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <rect height="3" width="10" x="3" />
    <path d="M15,5H1C0.4,5,0,5.4,0,6v6c0,0.6,0.4,1,1,1h2v2c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-2h2c0.6,0,1-0.4,1-1V6 C16,5.4,15.6,5,15,5z M11,14H5V9h6V14z" />
  </g>,
  'Print',
);
