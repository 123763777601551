import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <path
    fill-rule="evenodd"
    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4027 5.1527L6.6875 8.86789L5.5973 7.7777C5.22704 7.40743 4.64796 7.40743 4.2777 7.7777C3.90743 8.14796 3.90743 8.72704 4.2777 9.0973L6.0277 10.8473C6.10096 10.9206 6.1941 10.9954 6.3149 11.0491C6.43787 11.1037 6.56353 11.125 6.6875 11.125C6.81147 11.125 6.93713 11.1037 7.0601 11.0491C7.1809 10.9954 7.27404 10.9206 7.3473 10.8473L11.7223 6.4723C12.0926 6.10204 12.0926 5.52296 11.7223 5.1527C11.352 4.78243 10.773 4.78243 10.4027 5.1527Z"
  />,
  'CheckCircle',
);
