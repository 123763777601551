import React from 'react';
import createSvgIcon from '../../createSvgIcon';
export default createSvgIcon(
  <g clip-path="url(#clip0_1375_16210)">
    <path
      d="M7.87282 3.07106C7.76001 3.0716 7.64822 3.04959 7.54402 3.00634C7.43983 2.96309 7.34532 2.89946 7.26605 2.81919C6.56857 2.12359 5.6237 1.73296 4.63864 1.73296C3.65358 1.73296 2.70872 2.12359 2.01123 2.81919C1.85024 2.97999 1.632 3.0703 1.40447 3.0703C1.17693 3.0703 0.958693 2.97999 0.7977 2.81919C0.716695 2.74027 0.652313 2.64591 0.608354 2.5417C0.564395 2.43749 0.541748 2.32553 0.541748 2.21243C0.541748 2.09933 0.564395 1.98737 0.608354 1.88316C0.652313 1.77895 0.716695 1.6846 0.7977 1.60566C1.30313 1.09683 1.90423 0.693009 2.56637 0.417434C3.22851 0.141871 3.93862 0 4.65582 0C5.37301 0 6.08312 0.141871 6.74526 0.417434C7.40741 0.693009 8.00849 1.09683 8.51393 1.60566C8.59493 1.6846 8.65931 1.77895 8.70327 1.88316C8.74724 1.98737 8.76988 2.09933 8.76988 2.21243C8.76988 2.32553 8.74724 2.43749 8.70327 2.5417C8.65931 2.64591 8.59493 2.74027 8.51393 2.81919C8.43201 2.90556 8.33215 2.97288 8.22136 3.01639C8.11059 3.05992 7.99159 3.07858 7.87282 3.07106Z"
      fill="#DEEBFC"
    />
    <path
      d="M5.85744 5.6699C5.74436 5.67246 5.63197 5.65138 5.52749 5.608C5.423 5.56464 5.32872 5.49993 5.25068 5.41804C5.08373 5.26438 4.86511 5.17908 4.63819 5.17908C4.41128 5.17908 4.19266 5.26438 4.0257 5.41804C3.86478 5.582 3.64531 5.67532 3.41559 5.67747C3.18585 5.67961 2.96468 5.59042 2.80072 5.42949C2.63677 5.26857 2.54345 5.0491 2.5413 4.81937C2.53915 4.58964 2.62835 4.36847 2.78927 4.20451C3.27421 3.72143 3.9308 3.4502 4.61529 3.4502C5.29979 3.4502 5.95638 3.72143 6.44131 4.20451C6.60212 4.36551 6.69242 4.58374 6.69242 4.81127C6.69242 5.03881 6.60212 5.25705 6.44131 5.41804C6.28678 5.57462 6.07739 5.66495 5.85744 5.6699Z"
      fill="#DEEBFC"
    />
    <path
      d="M1.64485 9.17285C1.2976 9.17285 0.969113 9.33046 0.751827 9.60133C0.534542 9.8722 0.451943 10.2271 0.527272 10.566L1.67211 14.0005C1.78852 14.5244 2.25311 14.8971 2.78969 14.8971H9.65874C10.291 14.8971 10.8036 14.3845 10.8036 13.7522C10.8036 13.1199 10.291 12.6074 9.65874 12.6074H8.28741L7.3418 10.0693C7.22539 9.54553 6.7608 9.17285 6.22421 9.17285H1.64485Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9674 14.8969H15.9559C16.272 14.8969 16.5316 14.6396 16.4969 14.3254C16.2126 11.7491 14.0285 9.74512 11.3765 9.74512C10.5074 9.74512 9.68862 9.9603 8.97046 10.3404L9.28189 11.1762H9.65924C11.0819 11.1762 12.2352 12.3295 12.2352 13.7521C12.2352 14.1633 12.1388 14.552 11.9674 14.8969Z"
      fill="#0060E0"
    />
    <path
      d="M13.9513 5.45236C13.9513 6.87498 12.798 8.02825 11.3755 8.02825C9.95283 8.02825 8.79956 6.87498 8.79956 5.45236C8.79956 4.02973 9.95283 2.87646 11.3755 2.87646C12.798 2.87646 13.9513 4.02973 13.9513 5.45236Z"
      fill="#0060E0"
    />
  </g>,
  'EmployeeVisits',
);
