import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M15,16H1c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14C16,15.6,15.6,16,15,16z M2,14h12V2H2V14 z" />
    <path d="M6,4c0.6,0,1,0.4,1,1S6.6,6,6,6S5,5.6,5,5S5.4,4,6,4z" />
    <polygon points="3,12 5,8 7,10 10,6 13,12 " />
  </g>,
  'Image',
);
