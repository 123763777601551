import * as FullStory from '@fullstory/browser';
import { useEffect } from 'react';
import { Identity, UserBank, Link } from './interfaces';
import { PrimaryLinks, SecondaryLinks } from './useLayoutLinks';

declare global {
  const pendo: {
    initialize(config: {
      account: { id?: string };
      visitor: { id: string };
    }): void;
    updateOptions(config: {
      visitor: {
        id: string;
        isAdmin?: boolean;
        isManager?: boolean;
        hasInitiatives?: boolean;
        hasOneToOnes?: boolean;
        hasBank?: boolean;
      };
    }): void;
  };
}

const PRISM_PENDO_SCRIPT = 'prism-pendo-script';

const pendoScript = () => `
(function(apiKey){
(function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
v=['initialize','identify','updateOptions','pageLoad'];for(w=0,x=v.length;w<x;++w)(function(m){
o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})('55acdea7-9864-4dca-57b5-bef70c5fc999');
`;

const createScript = (id: string, body: string) => {
  const script = document.createElement('script');
  script.id = id;
  script.innerText = body;
  return script;
};

const hasLink = (key: string) => (link: Link) => link.id === key;

const useAnalytics = ({
  currentIdentity,
  fullStoryOrg,
  links,
  bank,
}: {
  currentIdentity?: Identity;
  fullStoryOrg: string;
  links: {
    primaryLinks: Link[];
    secondaryLinks: Link[];
  };
  bank: UserBank | undefined;
}) => {
  useEffect(() => {
    const shouldAddScripts = !document.getElementById(PRISM_PENDO_SCRIPT);

    if (shouldAddScripts) {
      document.head.appendChild(
        createScript(PRISM_PENDO_SCRIPT, pendoScript()),
      );
      FullStory.init({
        orgId: fullStoryOrg,
      });
    }
  }, [fullStoryOrg]);

  useEffect(() => {
    if (currentIdentity) {
      FullStory.identify(currentIdentity.identityUuid, {
        soldToParty_str: currentIdentity.customer?.stpNumber,
        systemUserId_str: currentIdentity.uniqueId,
        customerCoreUuid_str: currentIdentity.customer?.customerUuid,
        displayName: currentIdentity.identityUuid,
        identityCoreUuid_str: currentIdentity.identityUuid,
        isManager: currentIdentity.isManager,
      });
      pendo.initialize({
        account: {
          id: currentIdentity.customer?.customerUuid,
        },
        visitor: {
          id: currentIdentity.identityUuid,
        },
      });
    }
  }, [currentIdentity]);

  useEffect(() => {
    if (currentIdentity && bank && links?.primaryLinks?.length) {
      pendo.updateOptions({
        visitor: {
          id: currentIdentity.identityUuid,
          isAdmin: links.secondaryLinks?.some(hasLink(SecondaryLinks.Admin)),
          isManager: currentIdentity.isManager,
          hasInitiatives: links.primaryLinks.some(
            hasLink(PrimaryLinks.Initiatives),
          ),
          hasOneToOnes: links.primaryLinks.some(
            hasLink(PrimaryLinks.Initiatives),
          ),
          hasBank: !!bank,
        },
      });
    }
  }, [bank, currentIdentity, links]);
};

export default useAnalytics;
