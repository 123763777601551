import { useEffect } from 'react';
import useFeatureFlag from './useFeatureFlag';
import { get as readCookie } from 'js-cookie';

export default function useMaintenancePage() {
  const [isInit, maintenanceUrl] = useFeatureFlag<string>(
    'maintenance-redirect',
  );

  useEffect(() => {
    if (!isInit || !maintenanceUrl || maintenanceUrl === 'none') {
      return;
    }

    const override = readCookie('maintenance-override');
    if (override) {
      return;
    }

    // check domains
    const allowedDomains = [
      'culturecloud.com',
      'appreciatehub.com',
      'octanner.com',
      'octanner.net',
      'octanner.io',
      'octanner.app',
    ];
    const domainAndTop = new URL(maintenanceUrl).hostname
      .split('.')
      .slice(-2)
      .join('.');
    if (!allowedDomains.includes(domainAndTop)) {
      return;
    }

    window.location.href = maintenanceUrl;
  }, [maintenanceUrl]);
}
