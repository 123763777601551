export { default as Add } from './Add';
export { default as AddCircleOutline } from './AddCircleOutline';
export { default as Agenda } from './Agenda';
export { default as ArrowUp } from './ArrowUp';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowLeft } from './ArrowLeft';
export { default as ArrowRight } from './ArrowRight';
export { default as ArrowTailDown } from './ArrowTailDown';
export { default as ArrowTailLeft } from './ArrowTailLeft';
export { default as ArrowTailUp } from './ArrowTailUp';
export { default as ArrowTailRight } from './ArrowTailRight';
export { default as ArrowTriangleDown } from './ArrowTriangleDown';
export { default as ArrowTriangleLeft } from './ArrowTriangleLeft';
export { default as ArrowTriangleUp } from './ArrowTriangleUp';
export { default as ArrowTriangleRight } from './ArrowTriangleRight';
export { default as Attachment } from './Attachment';
export { default as Attention } from './Attention';
export { default as AvatarAdd } from './AvatarAdd';
export { default as AvatarCheck } from './AvatarCheck';
export { default as AvatarGroup } from './AvatarGroup';
export { default as Bookmark } from './Bookmark';
export { default as BulletedList } from './BulletedList';
export { default as Calendar } from './Calendar';
export { default as CalendarAdd } from './CalendarAdd';
export { default as Chart } from './Chart';
export { default as Chat } from './Chat';
export { default as ChatGroup } from './ChatGroup';
export { default as Check } from './Check';
export { default as CheckResponsive } from './CheckResponsive';
export { default as Checkbox } from './Checkbox';
export { default as Close } from './Close';
export { default as Copy } from './Copy';
export { default as Crop } from './Crop';
export { default as Desktop } from './Desktop';
export { default as Download } from './Download';
export { default as Document } from './Document';
export { default as DownloadCloud } from './DownloadCloud';
export { default as DownloadFile } from './DownloadFile';
export { default as Draggable } from './Draggable';
export { default as EditNote } from './EditNote';
export { default as Eye } from './Eye';
export { default as EyeSlash } from './EyeSlash';
export { default as Filter } from './Filter';
export { default as Flag } from './Flag';
export { default as FullScreen } from './FullScreen';
export { default as Gift } from './Gift';
export { default as Globe } from './Globe';
export { default as Goal } from './Goal';
export { default as Group } from './Group';
export { default as Heart } from './Heart';
export { default as Image } from './Image';
export { default as InboxCheck } from './InboxCheck';
export { default as InformationCircle } from './InformationCircle';
export { default as Library } from './Library';
export { default as Lock } from './Lock';
export { default as Mail } from './Mail';
export { default as MenuDots } from './MenuDots';
export { default as MenuHamburger } from './MenuHamburger';
export { default as Minus } from './Minus';
export { default as MinusInCircle } from './MinusInCircle';
export { default as Mobile } from './Mobile';
export { default as Notes } from './Notes';
export { default as Notification } from './Notification';
export { default as Pause } from './Pause';
export { default as Pencil } from './Pencil';
export { default as Person } from './Person';
export { default as Play } from './Play';
export { default as PlayWithCircle } from './PlayWithCircle';
export { default as PlayScreen } from './PlayScreen';
export { default as Power } from './Power';
export { default as Print } from './Print';
export { default as Refresh } from './Refresh';
export { default as Rotate } from './Rotate';
export { default as Search } from './Search';
export { default as Select } from './Select';
export { default as Send } from './Send';
export { default as SendUnfilled } from './SendUnfilled';
export { default as SettingsGear } from './SettingsGear';
export { default as Share } from './Share';
export { default as ShoppingCart } from './ShoppingCart';
export { default as VolumeLoud } from './VolumeLoud';
export { default as VolumeLow } from './VolumeLow';
export { default as VolumeMedium } from './VolumeMedium';
export { default as VolumeOff } from './VolumeOff';
export { default as Verified } from './Verified';
export { default as Star } from './Star';
export { default as Tag } from './Tag';
export { default as Trash } from './Trash';
export { default as Trophy } from './Trophy';
export { default as Unlock } from './Unlock';
export { default as Video } from './Video';
export { default as Wellness } from './Wellness';
export { default as Yearbook } from './Yearbook';
export { default as ZoomIn } from './ZoomIn';
export { default as Warning } from './Warning';
export { default as Write } from './Write';
export { default as Sort } from './Sort';
export { default as SortAscending } from './SortAscending';
export { default as SortDescending } from './SortDescending';
export { default as FileUpload } from './FileUpload';
export { default as Upload } from './Upload';
export { default as BrokenImage } from './BrokenImage';
export { default as FormatBold } from './FormatBold';
export { default as FormatItalic } from './FormatItalic';
export { default as FormatUnderline } from './FormatUnderline';
export { default as NumberedList } from './NumberedList';
export { default as Link } from './Link';
export { default as Alert } from './Alert';
export { default as CheckCircle } from './CheckCircle';
export { default as Home } from './Home';
export { default as HomeUnfilled } from './HomeUnfilled';
export { default as CartUnfilled } from './CartUnfilled';
export { default as PersonUnfilled } from './PersonUnfilled';
export { default as NotificationUnfilled } from './NotificationUnfilled';
export { default as SearchUnfilled } from './SearchUnfilled';
export { default as Sparkle } from './Sparkle';
export * from './admin-icons';
