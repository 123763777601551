import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M7,9L7,9C4.8,9,3,7.2,3,5V4c0-2.2,1.8-4,4-4h0c2.2,0,4,1.8,4,4v1C11,7.2,9.2,9,7,9z" />
    <path d="M5,11c-2.8,0-5,2.2-5,5h8v-5H5z" />
    <polygon points="14,10 12,10 12,12 10,12 10,14 12,14 12,16 14,16 14,14 16,14 16,12 14,12" />
  </g>,
  'AvatarAdd',
);
