import { createContext } from 'react';
import {
  CustomerPermission,
  DecodedAccessToken,
  Status,
} from '../../models/AuthContext';

export type AuthContextData = {
  status: Status;
  getAccessToken: () => Promise<string>;
  getDecodedToken: () => Promise<DecodedAccessToken>;
  getPermissions: () => Promise<CustomerPermission[]>;
  decodedToken?: DecodedAccessToken;
  permissions?: CustomerPermission[];
  invalidate: () => void;
};

const logError = <T>(): Promise<T> => {
  console.error(
    'AuthContext has not be initialized be sure it is wrapped in the <AuthContextProvider>',
  );
  return new Promise((_, reject) => reject('not_yet_initialized'));
};

export const defaultContext: AuthContextData = {
  status: 'not_initialized',
  getDecodedToken: logError,
  getAccessToken: logError,
  getPermissions: logError,
  invalidate: logError,
};

const AuthContext = createContext<AuthContextData>(defaultContext);

export default AuthContext;
