import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
    <g>
        <mask id="path-1-inside-1_8778_60" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.2091 14.2091 12 12 12C9.79086 12 8 10.2091 8 8C8 5.79086 9.79086 4 12 4C14.2091 4 16 5.79086 16 8ZM18.837 15.382C19.55 15.705 20 16.417 20 17.2V20H4V17.2C4 16.417 4.45 15.705 5.163 15.382C6.461 14.795 8.808 14 12 14C15.192 14 17.539 14.795 18.837 15.382Z"/>
        </mask>
        <path d="M18.837 15.382L19.2496 14.4711L19.2491 14.4708L18.837 15.382ZM20 20V21H21V20H20ZM4 20H3V21H4V20ZM5.163 15.382L4.75094 14.4708L4.75035 14.4711L5.163 15.382ZM12 13C14.7614 13 17 10.7614 17 8H15C15 9.65685 13.6569 11 12 11V13ZM7 8C7 10.7614 9.23858 13 12 13V11C10.3431 11 9 9.65685 9 8H7ZM12 3C9.23858 3 7 5.23858 7 8H9C9 6.34315 10.3431 5 12 5V3ZM17 8C17 5.23858 14.7614 3 12 3V5C13.6569 5 15 6.34315 15 8H17ZM21 17.2C21 16.0326 20.3277 14.9595 19.2496 14.4711L18.4244 16.2929C18.7723 16.4505 19 16.8014 19 17.2H21ZM21 20V17.2H19V20H21ZM4 21H20V19H4V21ZM3 17.2V20H5V17.2H3ZM4.75035 14.4711C3.67233 14.9595 3 16.0326 3 17.2H5C5 16.8014 5.22767 16.4505 5.57565 16.2929L4.75035 14.4711ZM12 13C8.6394 13 6.15188 13.8373 4.75094 14.4708L5.57506 16.2932C6.77012 15.7527 8.9766 15 12 15V13ZM19.2491 14.4708C17.8481 13.8373 15.3606 13 12 13V15C15.0234 15 17.2299 15.7527 18.4249 16.2932L19.2491 14.4708Z" fill="black" mask="url(#path-1-inside-1_8778_60)"/>
    </g>,
    'Person',
);

