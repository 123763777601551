import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M15,3H4.5L4,0.8C3.9,0.3,3.5,0,3,0H0v2h2.2L4,10.2C4.1,10.7,4.5,11,5,11h8c0.4,0,0.8-0.3,0.9-0.7l2-6 C16.1,3.8,15.8,3,15,3z" />
    <circle cx="5" cy="14" r="2" />
    <circle cx="13" cy="14" r="2" />
  </g>,
  'ShoppingCart',
);
