import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M2.91892 4.21622H12V5.51351H2.91892V4.21622ZM2.91892 8.10811H12V9.40541H2.91892V8.10811ZM0.972973 9.72973C1.50487 9.72973 1.94595 9.28865 1.94595 8.75676C1.94595 8.22486 1.51135 7.78378 0.972973 7.78378C0.434595 7.78378 0 8.22486 0 8.75676C0 9.28865 0.441081 9.72973 0.972973 9.72973Z" />
    <path d="M0.972973 1.94595C1.23102 1.94595 1.4785 1.84344 1.66097 1.66097C1.84344 1.4785 1.94595 1.23102 1.94595 0.972973C1.94595 0.714924 1.84344 0.467445 1.66097 0.284977C1.4785 0.102509 1.23102 0 0.972973 0C0.714925 0 0.467445 0.102509 0.284977 0.284977C0.102509 0.467445 0 0.714924 0 0.972973C0 1.23102 0.102509 1.4785 0.284977 1.66097C0.467445 1.84344 0.714925 1.94595 0.972973 1.94595Z" />
    <path d="M0.972973 5.83784C1.23102 5.83784 1.4785 5.73533 1.66097 5.55286C1.84344 5.37039 1.94595 5.12291 1.94595 4.86486C1.94595 4.60682 1.84344 4.35934 1.66097 4.17687C1.4785 3.9944 1.23102 3.89189 0.972973 3.89189C0.714925 3.89189 0.467445 3.9944 0.284977 4.17687C0.102509 4.35934 0 4.60682 0 4.86486C0 5.12291 0.102509 5.37039 0.284977 5.55286C0.467445 5.73533 0.714925 5.83784 0.972973 5.83784Z" />
    <path d="M2.91892 0.324324H12V1.62162H2.91892V0.324324Z" />
  </g>,
  'Bulletpoint',
);
