import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M15,0H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V1C16,0.4,15.6,0,15,0z M14,2v9h-3 c-0.6,0-1,0.4-1,1v1H6v-1c0-0.6-0.4-1-1-1H2V2H14z" />
    <path d="M6.3,9.7c0.5,0.5,1,0.4,1.4,0l4-4c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L7,7.6L5.7,6.3 c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L6.3,9.7z" />
  </g>,
  'InboxCheck',
);
