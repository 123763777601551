import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <polygon points="2,6 14,6 8,0 " />
    <polygon points="8,16 14,10 2,10 " />
  </g>,
  'Select',
);
