import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <circle cx="8" cy="8" r="2" />
    <circle cx="2" cy="8" r="2" />
    <circle cx="14" cy="8" r="2" />
  </g>,
  'MenuDots',
);
