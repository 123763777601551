import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M0 9.62424V12.9577C0 13.1345 0.0702398 13.3041 0.195267 13.4291C0.320295 13.5541 0.489869 13.6243 0.666685 13.6243H3.33342C3.51024 13.6243 3.67981 13.5541 3.80484 13.4291C3.92987 13.3041 4.00011 13.1345 4.00011 12.9577V9.62424H0Z" />
    <path d="M4.00011 8.29086V0.957326C4.00011 0.780511 3.92987 0.610937 3.80484 0.485909C3.67981 0.360882 3.51024 0.290642 3.33342 0.290642H0.666685C0.489869 0.290642 0.320295 0.360882 0.195267 0.485909C0.0702398 0.610937 0 0.780511 0 0.957326L0 8.29086H4.00011Z" />
    <path d="M5.3335 9.62424V12.9577C5.3335 13.1345 5.40374 13.3041 5.52876 13.4291C5.65379 13.5541 5.82337 13.6243 6.00018 13.6243H8.66692C8.84374 13.6243 9.01331 13.5541 9.13834 13.4291C9.26336 13.3041 9.3336 13.1345 9.3336 12.9577V9.62424H5.3335Z" />
    <path d="M9.3336 8.29086V0.957326C9.3336 0.780511 9.26336 0.610937 9.13834 0.485909C9.01331 0.360882 8.84374 0.290642 8.66692 0.290642H6.00018C5.82337 0.290642 5.65379 0.360882 5.52876 0.485909C5.40374 0.610937 5.3335 0.780511 5.3335 0.957326V8.29086H9.3336Z" />
    <path d="M10.9936 8.57958L14.8803 7.63555L13.1503 0.509359C13.1086 0.337617 13.0004 0.189459 12.8495 0.0974486C12.6986 0.00543851 12.5174 -0.0228937 12.3456 0.0186793L9.75354 0.64803C9.58254 0.690606 9.43535 0.799154 9.34415 0.949944C9.25295 1.10073 9.22516 1.2815 9.26686 1.45272L10.9936 8.57958Z" />
    <path d="M15.9812 12.171L15.1952 8.93161L11.3077 9.87497L12.0944 13.1144C12.1151 13.1995 12.1523 13.2797 12.2039 13.3504C12.2556 13.4212 12.3207 13.481 12.3954 13.5266C12.4702 13.5721 12.5533 13.6025 12.6398 13.616C12.7263 13.6295 12.8147 13.6258 12.8998 13.6051L15.4912 12.9764C15.6629 12.9345 15.8111 12.8262 15.903 12.6752C15.9948 12.5241 16.023 12.3428 15.9812 12.171Z" />
  </>,
  'Library',
);
