import { DateTime } from 'luxon';
import { DecodedAccessToken, CustomerPermission } from '../models/AuthContext';

export default class AuthUserInfo {
  public readonly accessToken: string;
  public readonly decodedAccessToken: DecodedAccessToken;
  public readonly permissions: CustomerPermission[];
  private readonly expiresAt: DateTime;

  constructor(
    accessToken: string,
    decodedAccessToken: DecodedAccessToken,
    permissions: CustomerPermission[],
  ) {
    this.accessToken = accessToken;
    this.decodedAccessToken = decodedAccessToken;
    this.permissions = permissions;
    this.expiresAt = DateTime.fromSeconds(decodedAccessToken.exp);
  }

  public isExpired(): boolean {
    return this.expiresAt < DateTime.now();
  }
}
