import React from 'react';
import createSvgIcon from '../../createSvgIcon';
export default createSvgIcon(
  <g clip-path="url(#clip0_1375_16233)">
    <path
      d="M11.8054 9.41208C14.3981 9.41208 16.5 7.30511 16.5 4.70604C16.5 2.10696 14.3981 0 11.8054 0C9.21266 0 7.11084 2.10696 7.11084 4.70604C7.11084 7.30511 9.21266 9.41208 11.8054 9.41208Z"
      fill="#0060E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.8068 2.79395C12.2119 2.79395 12.5403 3.12316 12.5403 3.52926V5.88228C12.5403 6.28839 12.2119 6.6176 11.8068 6.6176C11.4016 6.6176 11.0732 6.28839 11.0732 5.88228V3.52926C11.0732 3.12316 11.4016 2.79395 11.8068 2.79395Z"
      fill="#DEEBFC"
    />
    <path
      d="M2.66318 7.76514H0.5V12.4712L2.64592 14.6223C3.52631 15.5048 4.72039 16.0007 5.96546 16.0007H12.8232C13.7955 16.0007 14.5837 15.2106 14.5837 14.236C14.5837 13.2612 13.7955 12.4712 12.8232 12.4712H9.30229L5.98274 9.1435C5.10234 8.26094 3.90826 7.76514 2.66318 7.76514Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.84094 11.006C8.10007 11.3988 8.05711 11.9329 7.71205 12.2788C7.31793 12.6739 6.67893 12.6739 6.2848 12.2788L4.58301 10.5728C4.29655 10.2856 3.83211 10.2856 3.54566 10.5728C3.2592 10.8599 3.2592 11.3255 3.54566 11.6127L5.24745 13.3186C6.21449 14.288 7.78238 14.288 8.74942 13.3186C8.99834 13.0691 9.18318 12.7796 9.30396 12.4709H9.3022L7.84094 11.006Z"
      fill="#0060E0"
    />
  </g>,
  'PayrollIssued',
);
