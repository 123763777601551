import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path
      d="M0 10.6667H10.6667V8.88889H0V10.6667ZM0 0V1.77778H16V0H0ZM0 6.22222H16V4.44444H0V6.22222Z"
    />
  </>,
  'Notes',
);
