import { useEffect, useState } from 'react';
import favicon from './favicon';
import faviconNotification from './faviconNotification';

const useFavicon = (notifications: boolean = false) => {
  const [link, setLink] = useState<HTMLLinkElement | null>(null);

  useEffect(() => {
    let head = document.querySelector('head');

    if (!head) {
      head = document.createElement('head');
      document.insertBefore(head, document.body);
    }

    const links = head.querySelectorAll('link[rel*=icon]');
    links.forEach((link) => head?.removeChild(link));

    const pngLink = document.createElement('link');
    pngLink.rel = 'icon';
    pngLink.type = 'image/png';

    head.appendChild(pngLink);
    setLink(pngLink);
  }, []);

  useEffect(() => {
    if (link) {
      link.href = notifications ? faviconNotification : favicon;
    }
  }, [link, notifications]);
};

export default useFavicon;
