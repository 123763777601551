import React from 'react';
import createSvgIcon from '../../createSvgIcon';
export default createSvgIcon(
  <g clip-path="url(#clip0_1375_16217)">
    <path
      d="M0.5 1.56204C0.5 0.936127 1.01167 0.428711 1.64286 0.428711H15.3571C15.9883 0.428711 16.5 0.936127 16.5 1.56204V10.6287C16.5 11.2547 15.9883 11.762 15.3571 11.762H1.64286C1.01168 11.762 0.5 11.2547 0.5 10.6287V1.56204Z"
      fill="#DEEBFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.35696 4.39482C7.35696 5.33371 6.58945 6.09482 5.64267 6.09482C4.6959 6.09482 3.92839 5.33371 3.92839 4.39482C3.92839 3.45594 4.6959 2.69482 5.64267 2.69482C6.58945 2.69482 7.35696 3.45594 7.35696 4.39482ZM2.26707 8.70418C3.10296 7.79724 4.30563 7.22835 5.64229 7.22835C6.97894 7.22835 8.1816 7.79724 9.01751 8.70418C9.31443 9.02633 9.05154 9.49502 8.61144 9.49502H2.67314C2.23303 9.49502 1.97015 9.02633 2.26707 8.70418ZM10.6432 4.39534C10.6432 4.00414 10.963 3.68701 11.3575 3.68701H13.6432C14.0377 3.68701 14.3575 4.00414 14.3575 4.39534C14.3575 4.78655 14.0377 5.10368 13.6432 5.10368H11.3575C10.963 5.10368 10.6432 4.78655 10.6432 4.39534ZM11.3575 7.08675C10.963 7.08675 10.6432 7.40388 10.6432 7.79508C10.6432 8.18629 10.963 8.50342 11.3575 8.50342H13.6432C14.0377 8.50342 14.3575 8.18629 14.3575 7.79508C14.3575 7.40388 14.0377 7.08675 13.6432 7.08675H11.3575Z"
      fill="#0060E0"
    />
     ̰
  </g>,
  'EmployeeFile',
);
