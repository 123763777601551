import React from 'react';
import createSvgIcon from '../../createSvgIcon';

export default createSvgIcon(
  <g>
    <path
      d="M1.36227 0C1.04489 0 0.787598 0.258057 0.787598 0.576388V12.4492C0.787598 12.7675 1.04489 13.0256 1.36227 13.0256H4.39132C4.70872 13.0256 4.966 12.7675 4.966 12.4492V0.576388C4.966 0.258057 4.70872 0 4.39132 0H1.36227Z"
      fill="#0060E0"
    />
    <path
      d="M6.98556 5.98664C6.66818 5.98664 6.41088 6.2447 6.41088 6.56303V12.449C6.41088 12.7672 6.66818 13.0254 6.98556 13.0254H10.0146C10.332 13.0254 10.5893 12.7672 10.5893 12.449V6.56303C10.5893 6.2447 10.332 5.98664 10.0146 5.98664H6.98556Z"
      fill="#0060E0"
    />
    <path
      d="M12.0343 2.21651C12.0343 1.89818 12.2915 1.64012 12.609 1.64012H15.638C15.9554 1.64012 16.2126 1.89818 16.2126 2.21651V12.449C16.2126 12.7672 15.9554 13.0254 15.638 13.0254H12.609C12.2915 13.0254 12.0343 12.7672 12.0343 12.449V2.21651Z"
      fill="#0060E0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 15.1356C0.5 14.6581 0.885936 14.271 1.36201 14.271H15.638C16.114 14.271 16.5 14.6581 16.5 15.1356C16.5 15.6131 16.114 16.0002 15.638 16.0002H1.36201C0.885936 16.0002 0.5 15.6131 0.5 15.1356Z"
      fill="#DEEBFC"
    />
  </g>,
  'Activity',
);
