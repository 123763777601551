import { UserInfoResponse } from '../models/AuthContext';
import AuthUserInfo from './AuthUserInfo';
import IAuthUserInfoGetter from './IAuthUserInfoGetter';

export default class AuthUserInfoGetter implements IAuthUserInfoGetter {
  private readonly userInfoUri: string;
  constructor(userInfoUri: string) {
    this.userInfoUri = userInfoUri;
  }

  getUserInfo: () => Promise<AuthUserInfo> = async () => {
    const userInfoRes = await fetch(this.userInfoUri, {
      method: 'GET',
      credentials: 'include',
    });
    if (userInfoRes.status !== 200) {
      return Promise.reject(new Error('Failed to get user info'));
    }
    const body = (await userInfoRes.json()) as UserInfoResponse;
    return new AuthUserInfo(
      body.accessToken,
      body.decodedAccessToken,
      body.permissions,
    );
  };
}
