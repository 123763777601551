import { IFeatureFlagClient } from '../../core/IFeatureFlagClient';
import React from 'react';

export type FeatureFlagContextValue = {
  client?: IFeatureFlagClient;
};

const contextKey = Symbol.for('__OCTANNER_PRISM_UTILITIES__');

export default function getFeatureFlagContext(): React.Context<FeatureFlagContextValue> {
  let context = (React.createContext as any)[
    contextKey
  ] as React.Context<FeatureFlagContextValue>;
  if (!context) {
    Object.defineProperty(React.createContext, contextKey, {
      value: (context = React.createContext<FeatureFlagContextValue>({})),
      enumerable: false,
      writable: false,
      configurable: true,
    });
    context.displayName = 'FeatureFlagContext';
  }
  return context;
}
