import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <g>
    <g>
      <path d="M5.04347826,0 L4.34782609,0 C4.15547826,0 4,0.155478261 4,0.347826087 L4,2.7826087 C4,2.97495652 4.15547826,3.13043478 4.34782609,3.13043478 L5.04347826,3.13043478 C5.23582609,3.13043478 5.39130435,2.97495652 5.39130435,2.7826087 L5.39130435,0.347826087 C5.39130435,0.155478261 5.23582609,0 5.04347826,0 Z" />
      <path d="M12.0434783,3.13043478 L11.3478261,3.13043478 C11.1558261,3.13043478 11,2.9746087 11,2.7826087 L11,0.347826087 C11,0.155826087 11.1558261,0 11.3478261,0 L12.0434783,0 C12.2354783,0 12.3913043,0.155826087 12.3913043,0.347826087 L12.3913043,2.7826087 C12.3913043,2.9746087 12.2354783,3.13043478 12.0434783,3.13043478 Z" />
      <path d="M15.3043478,1 L13.2173913,1 L13.2173913,3.08695652 C13.2173913,3.27895652 13.0615652,3.43478261 12.8695652,3.43478261 L10.7826087,3.43478261 C10.5906087,3.43478261 10.4347826,3.27895652 10.4347826,3.08695652 L10.4347826,1 L5.56521739,1 L5.56521739,3.08695652 C5.56521739,3.27895652 5.4093913,3.43478261 5.2173913,3.43478261 L3.13043478,3.43478261 C2.93843478,3.43478261 2.7826087,3.27895652 2.7826087,3.08695652 L2.7826087,1 L0.695652174,1 C0.311304348,1 0,1.31130435 0,1.69565217 L0,14.9130435 C0,15.2973913 0.311304348,15.6086957 0.695652174,15.6086957 L15.3043478,15.6086957 C15.6886957,15.6086957 16,15.2973913 16,14.9130435 L16,1.69565217 C16,1.31130435 15.6886957,1 15.3043478,1 Z M15.2608696,15.0869565 L1.34782609,15.0869565 C1.15582609,15.0869565 1,14.9311304 1,14.7391304 L1,5.34782609 C1,5.15582609 1.15582609,5 1.34782609,5 L15.2608696,5 C15.4528696,5 15.6086957,5.15582609 15.6086957,5.34782609 L15.6086957,14.7391304 C15.6086957,14.9311304 15.4528696,15.0869565 15.2608696,15.0869565 Z" />
    </g>
    <rect height="1" width="11.2" x="3" y="7" />
    <rect height="1" width="8.8" x="3" y="9" />
    <rect height="1" width="5.6" x="3" y="12" />
  </g>,
  'Yearbook',
);
