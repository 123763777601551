import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <path
    d="M16.4507 0.319766C16.4407 0.309766 16.4307 0.299766 16.4207 0.299766C16.4107 0.289766 16.4007 0.289766 16.4007 0.289766C16.2607 0.189766 16.0907 0.109766 15.8907 0.0597656C15.5007 -0.0502344 15.1807 0.0497658 15.0207 0.0897658C14.3207 0.289766 9.81073 2.11977 4.84073 4.14977C3.33073 4.75977 2.14073 5.24977 1.86073 5.35977C1.34073 5.55977 0.990726 5.98977 0.910726 6.50977C0.830726 7.03977 1.05073 7.55977 1.49073 7.90977C1.74073 8.10977 2.48073 8.62977 3.19073 9.12977C3.53073 9.36977 3.88073 9.60977 4.13073 9.78977C4.13073 10.3998 4.13073 11.4598 4.13073 12.3498C4.13073 13.0298 4.13073 13.6298 4.13073 13.8898C4.13073 14.5698 4.48073 15.0998 5.09073 15.3298C5.67073 15.5498 6.32073 15.3398 6.71073 14.7898C6.88073 14.5498 7.66073 13.6498 8.12073 13.1098C8.17073 13.0498 8.22073 12.9998 8.26073 12.9498C9.21073 13.6798 11.2907 15.2698 11.8207 15.6298C12.0407 15.7598 12.4007 15.9998 12.8507 15.9998C12.9807 15.9998 13.1107 15.9798 13.2507 15.9398C13.7207 15.7898 14.0307 15.4098 14.1707 14.8198C14.1907 14.7298 16.7707 2.47977 16.8807 1.51977C16.9507 0.999766 16.7907 0.589766 16.4507 0.319766ZM2.40073 6.73977C2.39073 6.72977 2.38073 6.71977 2.37073 6.71977C2.38073 6.71977 2.38073 6.71977 2.39073 6.70977C2.68073 6.59977 3.82073 6.12977 5.39073 5.48977C7.37073 4.67977 10.3807 3.44977 12.6007 2.56977L4.85073 8.48977C4.62073 8.32977 4.34073 8.12977 4.03073 7.90977C3.37073 7.43977 2.61073 6.91977 2.40073 6.73977ZM5.59073 13.8598C5.59073 13.5898 5.59073 12.9998 5.59073 12.3298C5.59073 11.4398 5.59073 10.3698 5.59073 9.75977L12.6307 4.38977L7.57073 11.5198C7.57073 11.5298 7.56073 11.5298 7.56073 11.5398C7.54073 11.5598 7.52073 11.5798 7.50073 11.5998C7.40073 11.7098 7.24073 11.9098 7.04073 12.1398C6.38073 12.8998 5.82073 13.5598 5.59073 13.8598ZM12.7607 14.4698V14.4798C12.7207 14.4498 12.6807 14.4198 12.6507 14.4098C12.1807 14.0898 10.1307 12.5198 9.17073 11.7898L15.0307 3.53977C14.3007 7.14977 12.8707 14.0098 12.7607 14.4698Z"
    fill="black"
  />,
  'SendUnfilled',
);
