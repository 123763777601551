import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <path
    d="M8,16c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8C3.6,0,0,3.6,0,8C0,12.4,3.6,16,8,16z M15.3,8c0,4.1-3.3,7.3-7.3,7.3
	c-4.1,0-7.3-3.3-7.3-7.3c0-4.1,3.3-7.3,7.3-7.3C12.1,0.7,15.3,3.9,15.3,8z M7.7,9.3h1.1L9,3.8H7.4L7.7,9.3z M7.6,10.5
	c-0.2,0.1-0.2,0.4-0.2,0.7c0,0.3,0.1,0.5,0.2,0.7C7.7,11.9,8,12,8.2,12c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.4,0.3-0.7
	c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.2C8,10.3,7.7,10.3,7.6,10.5z"
  />,
  'Attention',
);
