import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <rect
    height="14"
    rx="3"
    ry="3"
    width="14"
    x="1"
    y="1"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
  />,
  'Checkbox',
);
